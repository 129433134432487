<template>
    <v-container class="a-container">
        <div :class="mobileCentering" class="display-1 primary-text font-weight-bold mb-2 mt-3">Administration</div>
        <v-divider></v-divider>
        <div :class="mobileCentering" class="headline mb-2 mt-3">Administrative KPIs</div>
        <v-row>
            <v-col
                cols="12"
                md="4"
                lg="3"
            >
                <admin-kpi-card
                    kpi-name="Users Logged In Last 7 Days"
                    kpi-slug="users-logged-in-last-7-days"
                ></admin-kpi-card>
            </v-col>
            <v-col
                cols="12"
                md="4"
                lg="3"
            >
                <admin-kpi-card
                    kpi-name="First Time Users Last 7 Days"
                    kpi-slug="first-time-users-last-7-days"
                ></admin-kpi-card>
            </v-col>
            <v-col
                cols="12"
                md="4"
                lg="3"
            >
                <admin-kpi-card
                    kpi-name="Users Logged In Last 30 Days"
                    kpi-slug="users-logged-in-last-30-days"
                ></admin-kpi-card>
            </v-col>
            <v-col
                cols="12"
                md="4"
                lg="3"
            >
                <admin-kpi-card
                    kpi-name="90 Day Inactive Users"
                    kpi-slug="90-day-inactive-users"
                ></admin-kpi-card>
            </v-col>
            <v-col
                cols="12"
                md="4"
                lg="3"
            >
                <admin-kpi-card
                    kpi-name="Users Never Logged In"
                    kpi-slug="users-never-logged-in"
                ></admin-kpi-card>
            </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <div :class="mobileCentering" class="headline mb-2 mt-3">Bulk Document Upload</div>
        <v-row>
            <v-col cols="12" md="3">
                <v-select
                    v-model="selectedDocType"
                    label="Document Type"
                    :items="docTypes"
                    item-text="type"
                    item-value="id"
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="3">
                <v-select
                    v-model="selectedDocSubType"
                    label="Document Subtype"
                    :items="docSubTypes"
                    item-text="subtype"
                    item-value="id"
                >
                </v-select>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card
                    class="drop-upload text-center"
                    height="100%"
                    width="100%"
                    elevation="0"
                    v-if="!files.length"
                    @dragover.prevent
                    @drop.prevent="addFiles"
                >
                    <p class="headline">Drag and Drop Files</p>
                    <p class="subtitle-1">OR</p>
                    <v-btn
                        tile
                        color="accent"
                        @click.stop="pickFiles"
                    >
                        Select Files <v-icon style="margin-left: .7rem;">mdi-file-document-box-multiple</v-icon>
                    </v-btn>
                    <input v-show="false" type="file" ref="filePicker" multiple="multiple" @change="addFiles">
                </v-card>
                <v-data-table
                    :headers="tableHeaders"
                    disable-sort
                    disable-filtering
                    v-if="files.length"
                    :items="formattedFilesForTable"
                >

                    <template v-slot:item.document_date="{item}">
                        <div v-if="!item.error">
                            <span v-text="item.document_date"></span>
                        </div>
                        <div v-if="item.error">
                            <span class="error-text" v-text="item.document_date"></span>
                        </div>
                    </template>
                    <template v-slot:item.subtype_value="{item}">
                        <div v-if="!item.error">
                            <span v-text="item.subtype_value"></span>
                        </div>
                        <div v-if="item.error">
                            <span class="error-text" v-text="item.subtype_value"></span>
                        </div>
                    </template>
                    <template v-slot:item.document_title="{item}">
                        <div v-if="!item.error">
                            <span v-text="item.document_title"></span>
                        </div>
                        <div v-if="item.error">
                            <span v-text="item.document_title" class="error-text"></span><br>
                            <span v-text="item.error" class="error-text"></span>
                        </div>
                    </template>
                    <template v-slot:item.status="{item}">
                        <v-tooltip left>
                            <template v-slot:activator="{on}">
                                <v-icon
                                    v-show="item.status.toLowerCase() === 'not uploaded'"
                                    v-on="on"
                                >
                                    mdi-record
                                </v-icon>
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    v-on="on"
                                    width="2"
                                    size="22"
                                    v-show="item.status.toLowerCase() === 'in progress'"
                                ></v-progress-circular>
                                <v-icon
                                    color="error"
                                    v-show="item.status.toLowerCase() === 'error'"
                                    v-on="on"
                                >
                                    mdi-close
                                </v-icon>
                                <v-icon
                                    color="success"
                                    v-show="item.status.toLowerCase() === 'success'"
                                    v-on="on"
                                >
                                    mdi-check
                                </v-icon>
                            </template>
                            <span>{{ item.status }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
            <v-col class="text-center" cols="12" v-show="showProgress || uploadComplete">
                <v-progress-linear
                    :color="errors ? 'error' : 'primary'"
                    height="25"
                    :value="completeFilesPercent"
                    striped
                    rounded
                >
                    <template v-slot="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                </v-progress-linear>
                <p class="mt-3 mb-0" v-show="uploadComplete">Complete! <span v-show="errors">But with {{ errors }} error(s).</span></p>
                <a class="mt-1" @click.prevent="startOver" style="text-decoration: underline" v-show="uploadComplete">Start a new upload.</a>
            </v-col>
            <v-col cols="12" md="3" class="text-center">
                <v-btn
                    color="primary"
                    block
                    tile
                    v-show="!hideUploadButton"
                    :disabled="uploadDisabled"
                    large
                    @click="uploadDocuments"
                    class="mb-3"
                >Upload
                    <v-icon style="margin-left: .7rem;">mdi-upload-multiple</v-icon>
                </v-btn>
                <a @click.prevent="startOver" v-show="!hideUploadButton" style="text-decoration: underline">Clear all files.</a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import AdminKpiCard from "../components/admin/AdminKpiCard";
    import {getDocumentTypes, getDocumentSubtypes, uploadDocument} from "../utils/api.utils";

    export default {
        name: "Admin",
        components: {AdminKpiCard},
        data() {
            return {
                kpis: [
                    {name: 'KPI Box', value: "XX"}
                ],
                files: [],
                selectedDocType: null,
                selectedDocSubType: null,
                docTypes: [],
                docSubTypes: [],
                showProgress: false
            }
        },
        mounted(){
            getDocumentTypes()
                .then(response => {
                    this.docTypes = response.data
                })
                .catch(error => {
                    console.log(error)
                });

            getDocumentSubtypes()
                .then(response => {
                    this.docSubTypes = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        methods: {

            uploadDocument(document, index){
                this.files[index].status = 'In Progress';
                let formData = new FormData();
                for (const key of Object.keys(document)){
                    formData.append(key, document[key]);
                }

                uploadDocument(formData)
                    .then(() =>{
                        this.files[index].status = 'Success'
                    })
                    .catch(err => {
                        this.files[index].error = err.response.data;
                        this.files[index].status = 'Error'
                    });



            },

            async uploadDocuments(){
                this.showProgress = true;
                let vm = this;
                for (let i = 0; i < vm.files.length; i++){
                    if (this.formattedFilesForUpload[i].status !== 'Error'){
                        this.uploadDocument(this.formattedFilesForUpload[i], i);
                    }
                }
            },


            pickFiles(){
                this.$refs.filePicker.click()
            },

            addFiles(e){
                let files = [...(e.target.files || e.dataTransfer.files)];
                let newFiles = [];
                files.forEach(file => {

                    let newFile = this.parseFile(file, this.selectedDocType, this.selectedDocSubType);

                    newFiles.push(newFile)
                });

                this.files = newFiles;
            },

            startOver(){
                this.files = [];
                this.showProgress = false;
            },

            convertDate(date){

                let month = date.slice(0, 2);
                let day = date.slice(2, 4);
                let year = date.slice(4);

                return `${year}-${month}-${day}`

            },

            parseFile(file){
                let parts = file.name.split('--');
                return {
                    document: file,
                    document_title: parts[2] ? parts[2] : file.name,
                    document_date: parts[2] ? this.convertDate(parts[1]) : 'Error',
                    subtype_value: parts[2] ? parts[0] : 'Error',
                    status: parts[2] ? 'Not Uploaded' : 'Error',
                    error: parts[2] ? '' : 'File name malformed.'
                }
            }
        },
        computed: {

            tableHeaders(){
                return [
                    {text: 'File', value: 'document_title'},
                    {text: 'Type', value: 'document_type'},
                    {text: 'Subtype', value: 'document_subtype'},
                    {text: this.subTypeLookup[this.selectedDocSubType] || 'Subtype Value', value: 'subtype_value'},
                    {text: 'Date', value: 'document_date'},
                    {text: 'Status', value: 'status', align: 'end'}
                ]
            },
            formattedFilesForUpload(){
                let formatted = [];
                this.files.forEach(file => {
                    let newFile = {...file};
                    newFile.document_type = this.selectedDocType;
                    newFile.document_subtype = this.selectedDocSubType;
                    formatted.push(newFile);
                });

                return formatted
            },

            formattedFilesForTable(){
                let formatted = [];
                this.files.forEach(file => {
                    let newFile = {...file};
                    newFile.document_type = this.typeLookup[this.selectedDocType];
                    newFile.document_subtype = this.subTypeLookup[this.selectedDocSubType];
                    formatted.push(newFile);
                });

                return formatted
            },

            typeLookup(){

                let lookup = {};
                this.docTypes.forEach(type => {
                    lookup[type.id] = type.type
                });
                return lookup

            },

            subTypeLookup(){

                let lookup = {};
                this.docSubTypes.forEach(type => {
                    lookup[type.id] = type.subtype
                });
                return lookup

            },

            isMobile(){
                return this.$vuetify.breakpoint.mdAndDown
            },

            mobileCentering(){
                return this.isMobile ? 'text-center' : ''
            },

            uploadPending(){
                let numPending = 0;
                this.files.forEach(file => {
                    if (file.status === 'In Progress') numPending++
                });

                return numPending > 0
            },

            completeFilesPercent(){
                let completeFiles = 0;
                this.files.forEach(file => {
                    if (['Success', 'Error'].includes(file.status)) completeFiles++
                });
                return (completeFiles / this.files.length * 100).toFixed(0)
            },

            uploadDisabled(){
                return !this.files.length || this.uploadPending || !this.selectedDocType || !this.selectedDocSubType
            },

            hideUploadButton(){
                return this.uploadPending || this.uploadComplete
            },

            uploadComplete(){
                return this.completeFilesPercent === "100"
            },

            errors(){
                let numErrors = 0;
                this.files.forEach(file => {
                    if (file.status === 'Error') numErrors++
                });

                return numErrors
            }
        }
    }
</script>

<style scoped>
.drop-upload{
    background: lightgray;
    padding: 2rem
}

.drop-upload .headline, .subtitle-1{
    color: gray;
}

.error-text{
    color: var(--v-error-base);
}

.theme--light.v-data-table {
    background-color: #fafafa !important;
    border: 1px solid lightgray;
}
</style>