<template>
    <download-csv
        :data="users"
        :name="kpiSlug + '.csv'"
    >
        <v-tooltip
            bottom
        >
            <template v-slot:activator="{on}">
                <v-card
                    tile
                    class="admin-kpi-card"
                    height="100%"
                    v-on="on"
                >
                    <v-card-text class="text-center">
                        <div v-if="!loading">
                            <span class="primary-color display-4" v-text="kpiValue">24</span>
                            <br>
                            <span class="kpi-description title font-weight-light" v-text="kpiName"></span>
                        </div>
                        <div v-if="loading">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="20"
                            ></v-progress-circular>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
            <span>Click to download user list.</span>
        </v-tooltip>
    </download-csv>
</template>

<script>
    import {getAdminKPI} from "../../utils/api.utils";


    export default {
        name: "AdminKpiCard",
        props: [
            'kpiName', 'kpiSlug'
        ],
        data() {
            return {
                users: [],
                loading: true,
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList(){
                this.loading = true;
                if (this.kpiSlug){
                    getAdminKPI(this.kpiSlug)
                        .then(response => {
                            this.users = response.data;
                            this.loading = false;
                        })
                        .catch(err => {
                            console.log(err);
                            this.loading = false;
                        })
                }

            }
        },
        computed: {
            kpiValue(){
                return this.users.length
            },

            slugWatcher(){
                return this.kpiSlug
            }
        },
        watch: {
            slugWatcher: function (){
                this.getList()
            }
        }
    }
</script>

<style scoped>
    .admin-kpi-card{
        background-color: var(--v-secondary-base);
        cursor: pointer;
    }

    .primary-color{
        color: var(--v-primary-base);
    }

    .kpi-description{
        color: white;
    }
</style>